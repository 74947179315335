<template>
  <a-layout class="custom-layout">
    <a-layout-sider class="custom-sider">
      <group-menu></group-menu>
    </a-layout-sider>
    <a-layout-content class="custom-content auto" style="position:relative;">
      <a-spin :spinning="loading">
        <div class="drawer-bar auto">
          <span class="font16"><b>组织机构管理</b></span>
        </div>
        <div class="department-content auto">
          <div class="depart-box depart-l fl">
            <div class="depart-title">
              <div class="depart-title-l fl"><b>组织机构</b></div>
              <div class="depart-title-r fr">
                <a-input-search v-model="searchValue" placeholder="请输入关键词" allowClear size="small" style="width:140px;" @change="onChange" />
                <span class="font12">可拖动排序</span>
              </div>
            </div>
            <div class="depart-con auto" :style="'height:' + boxHeight + 'px'">
              <a-tree
                v-if="treeData.length"
                ref="treeNode"
                :expanded-keys="expandedKeys"
                :draggable="true"
                @drop="dropHandle"
                @select="selectHandle"
                :blockNode="true"
                :replaceFields="{children:'children', title:'name', key:'id'}"
                :default-expand-all="true"
                :auto-expand-parent="autoExpandParent"
                :tree-data="treeData"
                @expand="onExpand"
              >
                <template slot="name" slot-scope="{ name, record }">
                  <div v-if="name.indexOf(searchValue) > -1" class="tree-node">
                    <div class="fl" :title="name">
                      {{ name.substr(0, name.indexOf(searchValue)) }}
                      <span style="color: #f50">{{ searchValue }}</span>
                      {{ name.substr(name.indexOf(searchValue) + searchValue.length) }}
                    </div>
                    <div class="fr">
                      <a-icon type="plus" @click.capture="plusHandle" />
                      <a-icon type="delete" @click.capture="deleteHandle" />
                    </div>
                  </div>
                  <div v-else class="tree-node">
                    <div class="fl" :title="name">{{ name }}</div>
                    <div class="fr">
                      <a-icon type="plus" @click.capture="plusHandle" />
                      <a-icon type="delete" @click.capture="deleteHandle" />
                    </div>
                  </div>
                </template>
              </a-tree>
            </div>
          </div>
          <div class="depart-box depart-r fr">
            <div class="depart-title">
              <div class="depart-title-l fl"><b>信息</b></div>
            </div>
            <div class="depart-con auto" v-show="isStart">
              <div id="no-margin-form" class="right-form">
                <a-form-model
                    layout="inline"
                    ref="itemForm"
                    :rules="itemRules"
                    :model="itemForm"
                    class="ant-advanced-search-form"
                    :label-col="{span: 6}"
                  >
                  <a-form-model-item label="单位名称" prop="name">
                    <a-input v-model="itemForm.name" placeholder="请输入单位名称" size="small" style="width:280px;"/>
                  </a-form-model-item>
                  <a-form-model-item label="单位代码" prop="code">
                    <a-input v-model="itemForm.code" placeholder="请输入单位代码" size="small" :disabled="!isAdd" style="width:280px;"/>
                  </a-form-model-item>
                  <a-form-model-item label="上级单位" prop="parentCode">
                    <span v-if="itemForm.parentCode">{{itemForm.parentName}}（代码：{{itemForm.parentCode}}）</span>
                  </a-form-model-item>
                  <a-form-model-item label="启用状态" prop="enabled">
                    <a-switch v-model.trim="itemForm.enabled" size="small" />
                  </a-form-model-item>
                  <a-form-model-item label="" :wrapper-col="{offset: 6}" style="margin-top:10px;">
                    <a-button type="primary" size="small" @click="saveDepartment">保存</a-button>
                  </a-form-model-item>
                </a-form-model>
              </div>
            </div>
          </div>
        </div>
      </a-spin>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import { Group } from '@/model'
export default {
  data () {
    return {
      boxHeight: 0,
      loading: true,
      autoExpandParent: true,
      isStart: false,
      treeData: [],
      searchValue: '',
      expandedKeys: [],
      itemForm: {
        code: '',
        name: '',
        level: '',
        parentCode: '',
        parentName: '',
        order: '',
        enabled: true
      },
      itemRules: {
        name: [
          { required: true, message: '请填写单位名称', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '单位代码不能为空', trigger: 'blur' }
        ]
      },
      dataList: [],
      current: {},
      departmentName: {},
      isAdd: false
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ])
  },
  mounted () {
    this.boxHeight = document.body.clientHeight - 250
  },
  methods: {
    deleteHandle () {
      this.$confirm({
        title: '提示',
        content: '确定要删除吗？',
        okText: '确认',
        cancelText: '取消',
        onOk: async () => {
          this.loading = true
          let res = await Group.deleteDepartment(this.current.id)
          this.loading = false
          if (!res['code']) {
            this.init()
            this.$message.success('删除成功')
            this.getDepartment()
          } else {
            this.$message.error(this.errMsg[res['code']])
          }
        }
      })
    },
    init () {
      this.isStart = false
      this.itemForm = {
        code: '',
        name: '',
        level: '',
        parentCode: '',
        parentName: '',
        order: '',
        enabled: true
      }
    },
    plusHandle (e) {
      setTimeout(() => {
        this.isAdd = true
        this.itemForm = {
          code: '',
          name: '',
          parentCode: this.current.id,
          parentName: this.current.name,
          level: 0,
          order: 0,
          enabled: true
        }
        // console.log('current', this.current)
      }, 10)
    },
    selectHandle (selectedKeys, e) {
      this.isAdd = false
      this.isStart = true
      // console.log(selectedKeys)
      // console.log(e)
      if (e.selectedNodes[0]) {
        // console.log(e.selectedNodes[0]['data']['props']['attr'])
        var data = e.selectedNodes[0]['data']['props']['attr']
        this.current = data
        this.itemForm = {
          code: data.id,
          name: data.name,
          parentCode: data.parentId,
          parentName: this.departmentName[data.parentId],
          level: data.level,
          order: data.order,
          enabled: data.isDisabled
        }
      }
    },
    dropHandle (info) {
      // console.log(info);
      const dropKey = info.node.eventKey;
      const dragKey = info.dragNode.eventKey;
      const dropPos = info.node.pos.split('-');
      const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
      const loop = (data, key, callback) => {
        data.forEach((item, index, arr) => {
          if (item.id === key) {
            return callback(item, index, arr);
          }
          if (item.children) {
            return loop(item.children, key, callback);
          }
        });
      };
      const data = [...this.treeData];

      // Find dragObject
      let dragObj;
      loop(data, dragKey, (item, index, arr) => {
        arr.splice(index, 1);
        dragObj = item;
      });
      if (!info.dropToGap) {
        // Drop on the content
        loop(data, dropKey, item => {
          item.children = item.children || [];
          // where to insert 示例添加到尾部，可以是随意位置
          item.children.push(dragObj);
        });
      } else if (
        (info.node.children || []).length > 0 && // Has children
        info.node.expanded && // Is expanded
        dropPosition === 1 // On the bottom gap
      ) {
        loop(data, dropKey, item => {
          item.children = item.children || [];
          // where to insert 示例添加到尾部，可以是随意位置
          item.children.unshift(dragObj);
        });
      } else {
        let ar;
        let i;
        loop(data, dropKey, (item, index, arr) => {
          ar = arr;
          i = index;
        });
        if (dropPosition === -1) {
          ar.splice(i, 0, dragObj);
        } else {
          ar.splice(i + 1, 0, dragObj);
        }
      }
      this.treeData = data;
      // console.log(this.treeData)
      this.sortDepartment()
    },
    async sortDepartment () {
      this.loading = true
      let res = await Group.sortDepartment(this.treeData)
      this.loading = false
      if (!res['code']) {
        this.$message.success('操作成功！')
        this.getDepartment()
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    onExpand (expandedKeys) {
      console.log('expandedKeys', expandedKeys)
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    getParentKey (key, tree) {
      let parentKey;
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
          if (node.children.some(item => item.id === key)) {
            parentKey = node.id;
          } else if (this.getParentKey(key, node.children)) {
            parentKey = this.getParentKey(key, node.children);
          }
        }
      }
      return parentKey;
    },
    onChange (e) {
      const value = e.target.value
      const expandedKeys = this.dataList
        .map(item => {
          if (item.name.indexOf(value) > -1) {
            return this.getParentKey(item.id, this.treeData)
          }
          return null;
        })
        .filter((item, i, self) => item && self.indexOf(item) === i);
        Object.assign(this, {
          expandedKeys,
          searchValue: value,
          autoExpandParent: true,
        })
    },
    async saveDepartment () {
      this.$refs['itemForm'].validate( async validate => {
        if (validate) {
          var regex = /^[A-Za-z0-9_]+$/;
          if (!regex.test(this.itemForm.code)) {
            this.$confirm({
              title: '单位代码只能包含大小写英文字母、数字和下划线'
            })
            return false
          }
          if (this.isAdd) {
            this.loading = true
            let res = await Group.addDepartment({
              id: this.itemForm.code,
              name: this.itemForm.name,
              parentId: this.itemForm.parentCode,
              level: Number(this.itemForm.level),
              isDisabled: this.itemForm.enabled ? 0 : 1,
              order: Number(this.itemForm.order)
            })
            this.loading = false
            if (!res['code']) {
              this.isStart = false
              this.$message.success('添加成功')
              this.getDepartment()
            } else {
              this.$message.error(this.errMsg[res['code']])
            }
          } else {
            this.loading = true
            let res = await Group.modifyDepartment(this.current.id, {
              id: this.itemForm.code,
              name: this.itemForm.name,
              parentId: this.itemForm.parentCode,
              level: Number(this.itemForm.level),
              isDisabled: this.itemForm.enabled ? 0 : 1,
              order: Number(this.itemForm.order)
            })
            this.loading = false
            if (!res['code']) {
              this.$message.success('保存成功')
              this.getDepartment()
            } else {
              this.$message.error(this.errMsg[res['code']])
            }
          }
        }
      })
    },
    trunData (data) {
      for (let k in data) {
        data[k]['scopedSlots'] = { title: 'name' }
        data[k]['attr']['isDisabled'] = Number(data[k]['attr']['isDisabled']) === 0 ? true : false
        this.departmentName[data[k]['id']] = data[k]['name']
        this.expandedKeys.push(data[k]['id'])
        this.dataList.push({ id: data[k]['id'], name: data[k]['name'] });
        if (data[k]['children']) {
          this.trunData(data[k]['children'])
        }
      }
    },
    async getDepartment () {
      this.loading = true
      let res = await Group.getDepartment('department')
      this.loading = false
      if (!res['code']) {
        this.trunData(res['data'])
        this.treeData = res['data']
        // console.log(res['data'])
      }
    }
  },
  created () {
    this.getDepartment()
  }
}
</script>

<style scoped>
.drawer-bar {
  padding: 8px 0 20px;
}

.depart-box {
  width: 49%;
}
.depart-l {
  border-right: 1px solid rgba(0,0,0,0.06);
  padding-right: 20px;
}
.depart-title {
  width: 100%;
  height: 30px;
  line-height: 30px;
  background: rgba(0,0,0,0.02);;
  border-bottom: 1px solid rgba(0,0,0,0.06);
}
.depart-title-l {
  margin-left: 24px;
}
.depart-title-r {
  margin-right: 24px;
}
.depart-title-r span {
  margin-left: 15px;
  /*color: #0091FF;*/
  font-size: 12px;
}

.tree-node .fr {
  color: #1890FF;
  margin-right: 10px;
  display: none;
}
.ant-tree-node-content-wrapper:hover .fr {
  display: block;
}
.tree-node .fr i {
  margin-right: 10px;
}
.tree-node .fl {
  max-width: 80%;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: auto;
  overflow: hidden;
}

.department-content {
  width: 70%;
  min-width: 910px;
}
.ant-form-item {
  width: 100%;
}
.right-form {
  margin-top: 5px;
}

.depart-con {
  width: 100%;
  overflow-y: auto;
}
</style>
