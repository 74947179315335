<template>
    <a-layout class="custom-layout">
      <a-layout-sider class="custom-sider">
        <system-menu></system-menu>
      </a-layout-sider>
      <a-layout-content class="custom-content auto" style="position:relative;">
        <a-spin :spinning="loading">
          <!-- <div class="topsubmenu auto">
            <a-radio-group default-value="1" size="small" @change="changeSubmenu" button-style="solid">
              <a-radio-button value="1">
                字段管理
              </a-radio-button>
            </a-radio-group>
          </div> -->
          <div class="content-title auto">
            <div class="coustom-title fl">字段管理（{{total}}个）</div>
          </div>
            
          <div class="coustom-title-mes auto">
            <a-icon type="info-circle" theme="filled" style="color:#226FBB;margin-right: 10px;" />一共有字段 {{total}} 个，其中预置字段 {{countData.nativeCount}} 个，扩展字段 {{countData.extendCount}} 个，派生字段 {{countData.derivedCount}} 个
          </div>
          <div class="content-bar auto">
            <custom-page :total="total" @getPageMes="getPageMes" :page="filter.current_page" :limit="filter.page_size">
              <div slot="custom">
                <a-button type="primary" size="small" class="m-r-5 fl" @click="addAttr"> 添加字段 </a-button>
                <a-input-search type="text" v-model="filter.nameEn" size="small" placeholder="请输入字段名称" class="m-r-5 fl" style="width:140px;" allowClear @search="onSearch"></a-input-search>
              </div>
            </custom-page>
          </div>
          <div class="content-table auto">
            <a-table :rowKey="(record,index)=>{return index}" :columns="tablecolumns" :data-source="tabledata" @change="onTableChange" size="middle" :pagination="false">
              <div slot="tableindex" slot-scope="text,record,index">
                {{ index + (filter.page_size * (filter.current_page - 1)) + 1 }}
              </div>
              <div slot="nameEn" slot-scope="text">
                {{ text }}
              </div>
              <div slot="nameZh" slot-scope="text">
                {{ text }}
              </div>
              <div slot="category" slot-scope="text">
                <span v-if="filters['category']">
                  {{ filters['category'] && filters['category'].find(item => {return item.id === text}) && filters['category'].find(item => {return item.id === text})['name'] }}
                </span>
              </div>
              <div slot="type" slot-scope="text">
                <span v-if="filters['type']">
                  {{ filters['type'] && filters['type'].find(item => {return item.id === text}) && filters['type'].find(item => {return item.id === text})['name'] }}
                </span>
              </div>
              <div slot="isDesensitization" slot-scope="text, record">
                <span v-if="text">
                  <a-badge color="#6DD400" /> 是
                  <a-tooltip v-if="record.desensitizationMode">
                    <template slot="title">
                      {{ filters['desensitization'] && filters['desensitization'].find(item => {return item.id === record.desensitizationMode}) && filters['desensitization'].find(item => {return item.id === record.desensitizationMode})['name'] }}
                    </template>
                    <a-icon type="info-circle" style="color: #FAAD14;" />
                  </a-tooltip>
                </span>
                <span v-else>
                  <a-badge color="#BEBEBE" /> 否
                </span>
              </div>
              <div slot="isCasAttribute" slot-scope="text">
                <span v-if="text">
                  <a-badge color="#6DD400" /> 是
                </span>
                <span v-else>
                  <a-badge color="#BEBEBE" /> 否
                </span>
              </div>
              <div slot="isEditabled" slot-scope="text">
                <span v-if="text">
                  <a-badge color="#6DD400" /> 是
                </span>
                <span v-else>
                  <a-badge color="#BEBEBE" /> 否
                </span>
              </div>
              <div slot="isIndex" slot-scope="text">
                <span v-if="text">
                  <a-badge color="#6DD400" /> 是
                </span>
                <span v-else>
                  <a-badge color="#BEBEBE" /> 否
                </span>
              </div>
              <div slot="isLdapAttribute" slot-scope="text">
                <span v-if="text">
                  <a-badge color="#6DD400" /> 是
                </span>
                <span v-else>
                  <a-badge color="#BEBEBE" /> 否
                </span>
              </div>
              <div slot="isFilterOperation" slot-scope="text, record">
                <span v-if="text">
                  <a-badge color="#6DD400" /> 是
                  <a-tooltip class="lightpopup" v-if="record.filterid">
                    <template slot="title">
                      {{ filters['filter'] && filters['filter'].find(item => {return item.id === record.filterid}) && filters['filter'].find(item => {return item.id === record.filterid})['name'] }}
                    </template>
                    <a-icon type="info-circle" style="color: #FAAD14;" />
                  </a-tooltip>
                </span>
                <span v-else>
                  <a-badge color="#BEBEBE" /> 否
                </span>
              </div>
              <div slot="action" slot-scope="text, record">
                <a-button type="primary" size="small" class="m-r-5" @click="editAttr(record)">编辑</a-button>
                <a-popconfirm
                  title="确定要删除吗?"
                  ok-text="确定"
                  cancel-text="取消"
                  placement="topRight"
                  @confirm="deleteAttr(record)"
                  v-if="record.category === 'DERIVED'"
                >
                  <a-button type="primary" size="small" class="bgred borred">删除</a-button>
                </a-popconfirm>
              </div>
            </a-table>
          </div>
          <div class="content-bar auto">
            <custom-page :total="total" @getPageMes="getPageMes" :page="filter.current_page" :limit="filter.page_size">
              <div slot="custom">
                <a-button type="primary" size="small" class="m-r-5 fl" @click="addAttr"> 添加字段 </a-button>
                <a-input-search type="text" v-model="filter.nameEn" size="small" placeholder="请输入字段名称" class="m-r-5 fl" style="width:140px;" allowClear @search="onSearch"></a-input-search>
              </div>
            </custom-page>
          </div>
        </a-spin>
      </a-layout-content>
      
      <a-modal v-model="addVisible" :title="isEdit ? '编辑字段' : '添加字段'" width="600px" @cancel="addClose">
        <a-form-model
          ref="addForm"
          :model="form"
          :rules="formRules"
          :label-col="{span: 5}"
          :wrapper-col="{span: 19}"
          class="ant-form-space-20"
        >
          <a-form-model-item label="字段类别" prop="category" v-if="!isEdit">
            <a-radio-group v-model="form.category">
              <a-radio value="EXTEND">
                扩展字段
              </a-radio>
              <a-radio value="DERIVED">
                派生字段
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="字段名" prop="nameEn">
            <template v-if="form.category !== 'DERIVED'">
              <a-input v-model="form.nameEn" placeholder="请输入字段名" size="small" style="width: 312px;" v-if="!isEdit"></a-input>
              <span v-else>{{ form.nameEn }}</span>
            </template>
            <template v-else>
              <a-select v-model="form.nameEn" placeholder="请选择字段名" size="small" style="width: 312px;" v-if="!isEdit">
                <a-select-option :value="item.id" v-for="(item, index) in filters['derived']" :key="index">
                  {{item.name}}
                </a-select-option>
              </a-select>
              <span v-else>{{ form.nameEn }}</span>
            </template>
          </a-form-model-item>
          <a-form-model-item label="字段类别" prop="category" v-if="isEdit">
            {{ filters['category'].find(item => {return item.id === form.category})['name'] }}
          </a-form-model-item>
          <a-form-model-item label="显示名称" prop="nameZh">
            <a-input v-model="form.nameZh" placeholder="请输入显示名称" size="small" style="width: 312px;"></a-input>
          </a-form-model-item>
          <a-form-model-item label="字段类型" prop="type" v-if="form.category !== 'DERIVED'">
            <a-select v-model="form.type" placeholder="请选择字段类型" size="small" style="width: 312px;" v-if="!isEdit">
              <a-select-option :value="item.id" v-for="(item, index) in filters['type']" :key="index">
                {{item.name}}
              </a-select-option>
            </a-select>
            <span v-else>{{ filters['type'].find(item => {return item.id === form.type})['name'] }}</span>
          </a-form-model-item>
          <a-form-model-item label="允许编辑" prop="isEditabled" v-if="form.category !== 'DERIVED'">
            <a-switch v-model="form.isEditabled" size="small" />
          </a-form-model-item>
          <a-form-model-item label="脱敏显示" prop="desensitizationMode" v-if="form.category !== 'DERIVED'">
            <a-switch v-model="form.isDesensitization" size="small" />
            <span style="color: rgba(0, 0, 0, 0.85);margin-left: 18px;" v-if="form.isDesensitization">脱敏方式：</span>
            <a-select v-model="form.desensitizationMode" v-if="form.isDesensitization" placeholder="请选择脱敏方式" size="small" style="width: 197px;">
              <a-select-option :value="item.id" v-for="(item, index) in filters['desensitization']" :key="index">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="创建索引" prop="isIndex" v-if="form.category !== 'DERIVED'">
            <a-switch :disabled="true" v-model="form.isIndex" size="small" />
          </a-form-model-item>
          <a-form-model-item label="作为扩展属性" prop="isCasAttribute">
            <a-switch v-model="form.isCasAttribute" size="small" />
          </a-form-model-item>
          <a-form-model-item label="作为LADP属性" prop="isLdapAttribute">
            <a-switch v-model="form.isLdapAttribute" size="small" />
          </a-form-model-item>
          <a-form-model-item label="参与筛选器运算" prop="isFilterOperation" v-if="form.category !== 'DERIVED'">
            <a-switch v-model="form.isFilterOperation" size="small" />
          </a-form-model-item>
        </a-form-model>
        <template slot="footer">
          <a-button key="back" @click="addClose">
            取 消
          </a-button>
          <a-button key="submit" type="primary" @click="addSave">
            确 定
          </a-button>
        </template>
      </a-modal>

      <a-modal v-model="showDeleteTip" :title="'删除（' + rowDetail.nameEn + '）'" width="400px" @cancel="deleteTipClose">
        <div style="color: rgba(0,0,0,0.85);">
          字段被占用，不能被删除， <a href="javascript:;" class="alink underline" @click="checkDetail">查看详情</a>
        </div>
        <template slot="footer">
          <a-button key="submit" type="primary" @click="deleteTipClose">
            不删除
          </a-button>
        </template>
      </a-modal>

      <a-modal v-model="showDetail" :title="'字段使用详情（' + rowDetail.nameEn + '）'" :footer="null" width="600px" @cancel="detailClose">
        <div class="detailtop auto">
          <a-radio-group v-model="detailTab" button-style="solid" size="small">
            <a-radio-button value="services">
              系统
            </a-radio-button>
            <a-radio-button value="filters">
              筛选器
            </a-radio-button>
          </a-radio-group>
        </div>
        <div class="detail-table auto m-t-10" v-if="detailTab === 'services'">
          
          <div class="content-table auto">
            <a-table :rowKey="(record,index)=>{return index}" :columns="tablecolumns2" :data-source="servicesTableData" size="middle" :pagination="false">
              <div slot="isCasAttribute" slot-scope="text">
                <span v-if="text">
                  <a-badge color="#6DD400" /> 是
                </span>
                <span v-else>
                  <a-badge color="#BEBEBE" /> 否
                </span>
              </div>
              <div slot="isLdapAttribute" slot-scope="text">
                <span v-if="text">
                  <a-badge color="#6DD400" /> 是
                </span>
                <span v-else>
                  <a-badge color="#BEBEBE" /> 否
                </span>
              </div>
            </a-table>
          </div>
          <div class="content-bar auto" v-if="total2 > filter2.page_size">
            <custom-page :total="total2" @getPageMes="getPageMes2" :page="filter2.current_page" :limit="filter2.page_size"></custom-page>
          </div>
        </div>
        <div class="detail-table auto m-t-10" v-if="detailTab === 'filters'">
          
          <div class="content-table auto">
            <a-table :rowKey="(record,index)=>{return index}" :columns="detailTableHeader" :data-source="detailTableData" size="middle" :pagination="false">
              <div slot="tags" slot-scope="text, record">
                <span v-if="text" class="filter-used">
                  <span v-for="(item, index) in record['tags']" :key="index">
                    {{ item['tagName'] }}
                    <template v-if="index !== record['tags'].length - 1">
                      <span>;</span>
                    </template>
                  </span>
                </span>
                <span v-else>
                  <a-badge color="#BEBEBE" /> 否
                </span>
              </div>
            </a-table>
          </div>
          <div class="content-bar auto" v-if="count3 > detailFilter.page_size">
            <custom-page :total="count3" @getPageMes="getPageMes3" :page="detailFilter.current_page" :limit="detailFilter.page_size"></custom-page>
          </div>
        </div>
      </a-modal>
      
    </a-layout>
  </template>
  
  <script>
  import { System } from '@/model'
  export default {
    data () {
      return {
        loading: true,
        filters: {},
        tablecolumns: [
          {
            title: '序号',
            dataIndex: 'tableindex',
            scopedSlots: { customRender: 'tableindex' }
          },
          {
            title: '字段名',
            dataIndex: 'nameEn',
            scopedSlots: { customRender: 'nameEn' }
          },
          {
            title: '字段类别',
            dataIndex: 'category',
            scopedSlots: { customRender: 'category' },
            filterMultiple: false,
            filters: [
              { text: '全部', value: '' },
              { text: '预置字段', value: 'NATIVE' },
              { text: '扩展字段', value: 'EXTEND' },
              { text: '派生字段', value: 'DERIVED' }
            ],
            customCell: () => {
              return {
                style: {
                  'width': '90px',
                }
              }
            }
          },
          {
            title: '显示名称',
            dataIndex: 'nameZh',
            scopedSlots: { customRender: 'nameZh' }
          },
          {
            title: '字段类型',
            dataIndex: 'type',
            scopedSlots: { customRender: 'type' }
          },
          {
            title: '允许编辑',
            dataIndex: 'isEditabled',
            scopedSlots: { customRender: 'isEditabled' }
          },
          {
            title: '脱敏显示',
            dataIndex: 'isDesensitization',
            scopedSlots: { customRender: 'isDesensitization' }
          },
          {
            title: '创建索引',
            dataIndex: 'isIndex',
            scopedSlots: { customRender: 'isIndex' }
          },
          {
            title: '作为扩展属性',
            dataIndex: 'isCasAttribute',
            scopedSlots: { customRender: 'isCasAttribute' }
          },
          {
            title: '作为LDAP属性',
            dataIndex: 'isLdapAttribute',
            scopedSlots: { customRender: 'isLdapAttribute' }
          },
          {
            title: '参与筛选器运算',
            dataIndex: 'isFilterOperation',
            scopedSlots: { customRender: 'isFilterOperation' }
          },
          {
            title: '操作',
            dataIndex: 'action',
            scopedSlots: { customRender: 'action' },
            customCell: () => {
              return {
                style: {
                  'width': '150px',
                }
              }
            }
          }
        ],
        tabledata: [],
        addVisible: false,
        isEdit: false,
        form: {
          nameEn: '',
          nameZh: '',
          type: 'VARCHAR',
          category: 'EXTEND',
          isIndex: false,
          isEditabled: true,
          isCasAttribute: true,
          isLdapAttribute: true,
          isFilterOperation: true,
          isDesensitization: true,
          desensitizationMode: 'id'
        },
        formRules: {
          nameEn: [{ required: true, message: '字段名不能为空', trigger: 'blur' }],
          nameZh: [{ required: true, message: '字段显示名称不能为空', trigger: 'blur' }],
          // desensitizationMode: [{ required: true, message: '脱敏方式不能为空', trigger: 'blur' }]
        },
        tablecolumns2: [
          {
            title: '系统名称',
            dataIndex: 'serviceName'
          },
          {
            title: '作为扩展属性',
            dataIndex: 'isCasAttribute',
            scopedSlots: { customRender: 'isCasAttribute' }
          },
          {
            title: '作为LDAP属性',
            dataIndex: 'isLdapAttribute',
            scopedSlots: { customRender: 'isLdapAttribute' }
          }
        ],
        servicesTableData: [],
        filter2: {
          current_page: 1,
          page_size: 10
        },
        total2: 0,
        filter: {
          // type: 'own',
          category: '',
          nameEn: '',
          nameZh: '',
          current_page: 1,
          page_size: 10
        },
        countData: {
          nativeCount: 0,
          extendCount: 0,
          derivedCount: 0
        },
        total: 0,
        current_row: {},
        detailTableLoading: false,
        detailTableHeader: [
          {
            title: '筛选器名称',
            dataIndex: 'filterName',
            customCell: () => {
              return {
                style: {
                  'min-width': '150px',
                }
              }
            }
          },
          {
            title: '关联标签',
            dataIndex: 'tags',
            scopedSlots: { customRender: 'tags' },
            customCell: () => {
              return {
                style: {
                  'max-width': '400px',
                }
              }
            }
          }
        ],
        detailTableData: [],
        count3: 0,
        detailFilter: {
          current_page: 1,
          page_size: 10
        },
        rowDetail: {},
        showDeleteTip: false,
        showDetail: false,
        detailTab: 'services'
      }
    },
    methods: {
      changeSubmenu (e) {
        var value = Number(e.target.value)
        console.log('value', value)
        switch (value) {
          case 1:
            this.$router.push('/system/attribute/index');
            break;
          default:
            break;
        }
      },
      addSave () {
        this.$refs['addForm'].validate( async validate => {
          if (validate) {
            this.loading = true

            let newForm = {}

            if (this.form.category === 'DERIVED') {
              newForm = {
                nameEn: this.form.nameEn,
                nameZh: this.form.nameZh,
                type: this.form.type,
                category: this.form.category,
                isCasAttribute: this.form.isCasAttribute ? 1 : 0,
                isLdapAttribute: this.form.isLdapAttribute ? 1 : 0
              }
            } else {
              newForm = {
                nameEn: this.form.nameEn,
                nameZh: this.form.nameZh,
                type: this.form.type,
                category: this.form.category,
                isIndex: this.form.isIndex ? 1 : 0,
                isEditabled: this.form.isEditabled ? 1 : 0,
                isCasAttribute: this.form.isCasAttribute ? 1 : 0,
                isLdapAttribute: this.form.isLdapAttribute ? 1 : 0,
                isFilterOperation: this.form.isFilterOperation ? 1 : 0,
                isDesensitization: this.form.isDesensitization ? 1 : 0,
                desensitizationMode: this.form.desensitizationMode
              }
            }

            if (this.isEdit) {
              let res = await System.putAttribute(this.current_row['id'], newForm)
              if (!res['code']) {
                this.$message.success('操作成功！')
                this.addClose()
                this.getAuthorizationUser()
              } else {
                this.$message.error(this.errMsg[res['code']])
              }
            } else {
              let res = await System.postAttribute(newForm)
              if (!res['code']) {
                this.$message.success('操作成功！')
                this.addClose()
                this.getAuthorizationUser()
              } else {
                this.$message.error(this.errMsg[res['code']])
              }
            }
          }
        })
      },
      editAttr (row) {
        this.isEdit = true
        var newRow = JSON.parse(JSON.stringify(row))
        newRow['isIndex'] = newRow['isIndex'] ? true : false
        newRow['isEditabled'] = newRow['isEditabled'] ? true : false
        newRow['isCasAttribute'] = newRow['isCasAttribute'] ? true : false
        newRow['isLdapAttribute'] = newRow['isLdapAttribute'] ? true : false
        newRow['isFilterOperation'] = newRow['isFilterOperation'] ? true : false
        newRow['isDesensitization'] = newRow['isDesensitization'] ? true : false
        this.current_row = newRow
        this.form = {
          nameEn: newRow.nameEn,
          nameZh: newRow.nameZh,
          type: newRow.type,
          category: newRow.category,
          isIndex: newRow.isIndex,
          isEditabled: newRow.isEditabled,
          isCasAttribute: newRow.isCasAttribute,
          isLdapAttribute: newRow.isLdapAttribute,
          isFilterOperation: newRow.isFilterOperation,
          isDesensitization: newRow.isDesensitization,
          desensitizationMode: newRow.desensitizationMode
        }
        this.addVisible = true
      },
      addClose () {
        this.form = {
          nameEn: '',
          nameZh: '',
          type: 'VARCHAR',
          category: 'EXTEND',
          isIndex: false,
          isEditabled: true,
          isCasAttribute: true,
          isLdapAttribute: true,
          isFilterOperation: true,
          isDesensitization: true,
          desensitizationMode: 'id'
        }
        this.$refs['addForm'].resetFields()
        this.addVisible = false
      },
      onSearch (e) {
        this.filter.current_page = 1
        this.getAuthorizationUser()
      },
      getPageMes3 (obj) {
        this.detailFilter.current_page = obj.pageNumber
        this.detailFilter.page_size = obj.pageSize
        this.getDetailPageData()
      },
      getDetailPageData () {
        let tableData = JSON.parse(JSON.stringify(this.rowDetail['filters']))
        this.detailTableData = tableData.splice((this.detailFilter.current_page - 1) * this.detailFilter.page_size, this.detailFilter.current_page * this.detailFilter.page_size)
      },
      detailClose () {
        this.detailTableData = []
        this.detailFilter = {
          current_page: 1,
          page_size: 10
        }
      },
      addAttr () {
        this.addVisible = true
        this.isEdit = false
      },
      deleteTipClose () {
        this.showDeleteTip = false
      },
      checkDetail () {
        this.showDeleteTip = false
        this.showDetail = true
        this.detailTab = 'services'
        this.filter2 = {
          page_size: 10,
          current_page: 1
        }
        this.detailFilter = {
          page_size: 10,
          current_page: 1
        }
      },
      async deleteAttr (row) {
        let res = await System.checkAttributeDetail(row.id)
        if (!res['code']) {
          if (res['data']) {
            this.current_row = row
            this.rowDetail = res['data']
            this.total2 = res['data']['services'].length
            this.count3 = res['data']['filters'].length
            this.showDeleteTip = true
            this.getPageMes2({
              pageNumber: 1,
              pageSize: 10
            })
            this.getPageMes3({
              pageNumber: 1,
              pageSize: 10
            })
          } else {
            this.deleteAttrHandle(row)
          }
        } else {
          this.$message.error(this.errMsg[res['code']])
        }
      },
      async deleteAttrHandle (row) {
        let res = await System.deleteAttribute(row.id)
        if (!res['code']) {
          this.$message.success('删除成功！')
          this.getAuthorizationUser()
        } else {
          this.$message.error(this.errMsg[res['code']])
        }
      },
      getPageMes2 (obj) {
        this.filter2.current_page = obj.pageNumber
        this.filter2.page_size = obj.pageSize
        this.getServicesPageData()
      },
      getServicesPageData () {
        let tableData = JSON.parse(JSON.stringify(this.rowDetail['services']))
        this.servicesTableData = tableData.splice((this.filter2.current_page - 1) * this.filter2.page_size, this.filter2.current_page * this.filter2.page_size)
      },
      getPageMes (obj) {
        this.filter.current_page = obj.pageNumber
        this.filter.page_size = obj.pageSize
        this.getAuthorizationUser()
      },
      async getAuthorizationUserAll () {
        let res = await System.getAttributeQuery({
          current_page: 1,
          page_size: 999999999
        })
        if (!res['code']) {
          this.filters['tableDataAll'] = res['data']
        } else {
          this.$message.error(this.errMsg[res['code']])
        }
      },
      onTableChange (pagination, filters, sorter) {
        console.log('filters', filters)
        this.filter['category'] = filters['category'][0]
        this.filter.current_page = 1
        this.filter.page_size = 10
        this.getAuthorizationUser()
      },
      async getAuthorizationUser () {
        this.loading = true
        let newFilter = JSON.parse(JSON.stringify(this.filter))
        newFilter['nameEn'] = '%' + this.filter['nameEn'] + '%'
        newFilter['nameZh'] = newFilter['nameEn']
        let res = await System.getAttributeQuery(newFilter)
        this.loading = false
        if (!res['code']) {
          this.tabledata = res['data']
          this.total = res['total']
        } else {
          this.$message.error(this.errMsg[res['code']])
        }
        let res2 = await System.getAttributeCount({
          nameEn: newFilter['nameEn'],
          nameZh: newFilter['nameZh']
        })
        if (!res2['code']) {
          this.countData = res2['data']
        } else {
          this.$message.error(this.errMsg[res2['code']])
        }
        this.getAuthorizationUserAll()
      },
      async getAuthorizationCode () {
        let res = await System.getAttributeCode('type')
        if (!res['code']) {
          this.filters['type'] = res['data']
        } else {
          this.$message.error(this.errMsg[res['code']])
        }
        let res2 = await System.getAttributeCode('category')
        if (!res2['code']) {
          this.filters['category'] = res2['data']
        } else {
          this.$message.error(this.errMsg[res2['code']])
        }
        let res3 = await System.getAttributeCode('desensitization')
        if (!res3['code']) {
          this.filters['desensitization'] = res3['data']
        } else {
          this.$message.error(this.errMsg[res3['code']])
        }
        let res4 = await System.getAttributeCode('filter')
        if (!res4['code']) {
          this.filters['filter'] = res4['data']
        } else {
          this.$message.error(this.errMsg[res4['code']])
        }
        let res5 = await System.getAttributeCode('derived')
        if (!res5['code']) {
          this.filters['derived'] = res5['data']
        } else {
          // this.$message.error(res2['description'])
          this.$message.error(this.errMsg[res5['code']])
        }
        this.getAuthorizationUser()
      }
    },
    created () {
      this.getAuthorizationCode()
    },
    mounted () {
    }
  };
  </script>
  
  <style scoped>
  .content-bar {
    padding: 8px 0;
  }
  .log-page {
    padding: 20px 0;
  }
  
  .roles_item {
    display: inline-block;
    width: 140px;
    /*height: 20px;*/
    line-height: 30px;
    margin-bottom: -10px;
    overflow: hidden;
  }
  
  .current_roles_box {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
  }
  
  .topsubmenu {
    padding-bottom: 0;
  }
  .coustom-title {
    color: rgba(0,0,0,0.85);
  }

  .filter-used {
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  </style>
  