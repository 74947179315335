import Http from '@/common/http'
export default class User {
  static getUserList (param = {}) {
    return Http('get', 'api/user/query', param)
  }
  static getUserCount (param = {}) {
    return Http('get', 'api/user/count', param)
  }
  static getUserExport (param = {}) {
    return Http('get', 'api/user/export', param)
  }
  static getUserDetail (id, param = {}) {
    return Http('get', 'api/user/detail/' + id, param)
  }
  static getUserImage (id, param = {}) {
    return Http('get', 'api/user/detail/' + id + '/image', param)
  }
  static userDetail (id, param = {}) { // 单个人员修改
    return Http('put', 'api/user/detail/' + id, param)
  }
  static userAdd (param = {}) { // 单个新增
    return Http('post', 'api/user/detail', param)
  }
  static userBatchModify (param = {}) { // 单个人员修改
    return Http('put', 'api/user/modify/', param)
  }
  static deleteUser (id, param = {}) { // 单个人员删除
    return Http('delete', 'api/user/detail/' + id, param)
  }
  static userBatchRemove (param = {}) { // 批量人员账号删除
    return Http('delete', 'api/user/remove', param)
  }
  static getAuditLog (id, param = {}) { // 操作日志查询
    return Http('get', 'api/user/detail/' + id + '/auditlog', param)
  }
  static getUserLoginLog (id, param = {}) { // 登录日志查询
    return Http('get', 'api/user/detail/' + id + '/loginlog', param)
  }
  static getUserMultifactor (id, param = {}) { // 双因素查询
    return Http('get', 'api/user/detail/' + id + '/multifactor', param)
  }
  static putUserMultifactor (id, param = {}) { // 双因素修改
    return Http('put', 'api/user/detail/' + id + '/multifactor', param)
  }
  static getUserNotification (id, param = {}) { // 消息提醒查询
    return Http('get', 'api/user/detail/' + id + '/notification', param)
  }
  static unbindTotp (id, param = {}) { // 解绑totp
    return Http('put', 'api/user/detail/' + id + '/totp/unbind', param)
  }
  static putUserNotification (id, param = {}) { // 消息提醒修改
    return Http('put', 'api/user/detail/' + id + '/notification', param)
  }
  static getBatchUserResult (param = {}) { // 人员导入展示
    return Http('get', 'api/user/tempfile/query', param)
  }
  static getBatchUserMes (param = {}) { // 人员导入统计
    return Http('get', 'api/user/tempfile/count', param)
  }
  static getBatchUserMesStatus (param = {}) { // 人员导入统计状态查询
    return Http('get', 'api/user/tempfile/status', param)
  }
  static batchUserExecute (id, param = {}) { // 人员导入执行新增
    return Http('post', 'api/user/import/execute/add/' + id, param)
  }
  static batchUserExecuteModify (id, param = {}) { // 人员修改执行修改
    return Http('put', 'api/user/import/execute/modify/' + id, param)
  }
  static batchUserUpdate (id, param = {}) { // 人员导入覆盖更新
    return Http('put', 'api/user/import/execute/' + id, param)
  }
  static getBatchAccountResult (batch_id, type, param = {}) { // 账号导入展示
    return Http('get', 'api/user/account/import/query/' + batch_id + '/' + type, param)
  }
  static getBatchAccountMes (batch_id) { // 账号导入统计
    return Http('get', 'api/user/account/import/count/' + batch_id)
  }
  static getBatchAccountMesStatus (batch_id) { // 账号导入统计
    return Http('get', 'api/user/account/import/status/' + batch_id)
  }
  static batchAccountExecute (id, param = {}) { // 人员导入执行新增
    return Http('post', 'api/user/account/import/execute/add/' + id, param)
  }
  static batchAccountExecuteModify (id, param = {}) { // 账号导入执行修改
    return Http('put', 'api/user/account/import/execute/modify/' + id, param)
  }
  static batchAccountUpdate (id, param = {}) { // 人员导入覆盖更新
    return Http('put', 'api/user/account/import/execute/' + id, param)
  }
  static getBatchUserAccountResult (batch_id, type, param = {}) { // 账号导入展示
    return Http('get', 'api/user/extend/import/query/' + batch_id + '/' + type, param)
  }
  static getBatchUserAccountMes (batch_id) { // 账号导入统计
    return Http('get', 'api/user/extend/import/count/' + batch_id)
  }
  static getBatchUserAccountMesStatus (batch_id) { // 账号导入统计
    return Http('get', 'api/user/extend/import/status/' + batch_id)
  }
  static batchUserAccountExecute (id, param = {}) { // 人员导入执行新增
    return Http('post', 'api/user/extend/import/execute/add/' + id, param)
  }
  static batchUserAccountExecuteModify (id, param = {}) { // 人员账号导入执行新增
    return Http('put', 'api/user/extend/import/execute/modify/' + id, param)
  }
  static batchUserAccountUpdate (id, param = {}) { // 人员导入覆盖更新
    return Http('put', 'api/user/extend/import/execute/' + id, param)
  }
  static exportDownload (taskid) { // 下载
    return Http('get', 'api/user/export/download?taskid=' + taskid)
  }
  static exportDownloadStatus (param = {}) { // 下载
    return Http('get', 'api/user/export/status', param)
  }
  static getUserCodeTag (param = {}) { // 获取标签代码
    return Http('get', 'api/user/code/tag', param)
  }
}
