<template>
  <a-layout class="custom-layout">
    <a-layout-sider class="custom-sider">
      <group-menu></group-menu>
    </a-layout-sider>
    <a-layout-content class="custom-content auto" style="position:relative;">
      <a-spin :spinning="loading">
        <div class="drawer-bar auto">
          <span class="font16"><b>标签管理</b></span>
        </div>
        <div class="department-content auto">
          <div class="depart-box depart-l fl">
            <div class="depart-title">
              <div class="depart-title-l fl"><b>标签</b></div>
              <div class="depart-title-r fr">
                <a-input-search v-model="searchValue" placeholder="请输入关键词" allowClear size="small" style="width:140px;margin-right: 5px;" />
                <a href="javascript:;" @click="addTag">添加</a>
              </div>
            </div>
            <div class="depart-con left-con auto" :style="'minHeight:' + boxHeight + 'px'">
              <ul>
                <li v-for="(item, index) in treeData" :key="index" @click="chooseList(item)" :class="current.id === item.id ? 'cur' : ''" v-show="item.name.indexOf(searchValue) > -1">
                  <a-icon type="tags" theme="filled" class="fl dynamic-icon" style="color: #CCB67D;" v-if="item.type === 'dynamic'" />
                  <a-icon type="tags" theme="filled" class="fl dynamic-icon" style="color: #91D5FF;" v-else-if="item.type === 'dynamic_runtime'" />
                  <a-icon type="tags" theme="filled" class="fl dynamic-icon static-icon" style="color: #94BA85;" v-else />
                  <span class="fl" :title="item.name">{{item.name}}</span>
                  <a-popconfirm
                    title="确定要删除吗?"
                    ok-text="确定"
                    cancel-text="取消"
                    placement="topRight"
                    @confirm="deleteHandle(item)"
                  >
                    <a-icon type="delete" class="fr" @click.stop />
                  </a-popconfirm>
                  <a-icon type="unordered-list" class="fr detail" @click.stop="checkDetail(item)" />
                </li>
              </ul>
            </div>
          </div>
          <div class="depart-box depart-r fr">
            <div class="depart-title">
              <div class="depart-title-l fl"><b>信息</b></div>
            </div>
            <div class="depart-con auto" v-show="isStart">
              <div id="no-margin-form" class="right-form">
                <a-form-model
                    layout="inline"
                    ref="itemForm"
                    :rules="itemRules"
                    :model="itemForm"
                    class="ant-advanced-search-form"
                    :label-col="{span: 6}"
                    :wrapper-col="{span: 18}"
                  >
                  <a-form-model-item label="标签名称" prop="name">
                    <a-input v-model="itemForm.name" placeholder="请输入类别名称" size="small" allowClear style="width:90%;"/>
                  </a-form-model-item>
                  <a-form-model-item label="标签类型" prop="code">
                    <a-radio-group :options="options" v-model="itemForm.type" :disabled="true" />
                  </a-form-model-item>
                  <a-form-model-item label="单个添加" prop="name" v-show="itemForm.type === 'static'">
                    <a-input v-model="schoolid" placeholder="请输入学工号" size="small" class="fl" allowClear style="width:calc(90% - 54px);margin-top: 3px;"/>
                    <a-button type="primary" size="small" @click="addUser" class="fr" style="margin-top: 3px;margin-right:10%;">添加</a-button>
                  </a-form-model-item>
                  <a-form-model-item label="批量导入" prop="name" v-show="itemForm.type === 'static'">
                    请先
                    <a-button size="small" @click="download">下载模板</a-button>
                    ，编辑后再
                    <a-upload
                        name="file1"
                        :action="config.api + 'tag/static/user/batch/' + current.id"
                        @change="handleChange"
                        :showUploadList="false"
                      >
                      <a-button size="small">批量导入</a-button>
                    </a-upload>
                  </a-form-model-item>
                  <a-form-model-item label="筛选器" prop="filterid" v-show="itemForm.type === 'dynamic'">
                    <a-select v-model="itemForm.filterid" placeholder="请选择类型" size="small" allowClear style="width:90%;">
                      <a-select-option :value="labelSelectItem.id" v-for="(labelSelectItem, labelSelectIndex) in filterList" :key="labelSelectIndex" v-if="labelSelectItem.type === 'standard'">{{labelSelectItem.name}}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item label="筛选器" prop="filterid" v-show="itemForm.type === 'dynamic_runtime'">
                    <a-select v-model="itemForm.filterid" placeholder="请选择类型" size="small" allowClear style="width:90%;">
                      <a-select-option :value="labelSelectItem.id" v-for="(labelSelectItem, labelSelectIndex) in filterList" :key="labelSelectIndex" v-if="labelSelectItem.type === 'runtime'">{{labelSelectItem.name}}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item label="" prop="" :wrapper-col="{span: 18,offset: 6}" v-show="itemForm.type === 'dynamic' || itemForm.type === 'dynamic_runtime'">
                    <div class="tag-btns auto">
                      <a-button size="small" @click="editFilter" class="fl">编辑筛选器</a-button>
                      <a href="javascript:;" class="fr underline" @click="prviewUsers" v-show="itemForm.type === 'dynamic'">预览成员</a>
                    </div>
                  </a-form-model-item>
                  <div class="tag-table auto" v-show="showTable">
                    <a-table :loading="tableLoading" :rowKey="(record,index)=>{return record.id}" v-if="itemForm.type === 'dynamic' || itemForm.type === 'dynamic_runtime'" :columns="tablecolumns" :data-source="tabledata" size="middle" :pagination="false">
                      <div slot="department" slot-scope="text">
                        <span v-for="(item, index) in text" :key="index">{{departmentName[item]}}</span>
                      </div>
                    </a-table>
                    <a-table :loading="tableLoading" :rowKey="(record,index)=>{return record.id}" v-if="itemForm.type === 'static'" :columns="tablecolumns2" :data-source="tabledata" size="middle" :pagination="false">
                      <div slot="department" slot-scope="text">
                        <span v-for="(item, index) in text" :key="index">{{departmentName[item]}}</span>
                      </div>
                      <div slot="action" slot-scope="text, record">
                        <a-popconfirm
                          title="确定要删除吗?"
                          ok-text="确定"
                          cancel-text="取消"
                          placement="topRight"
                          @confirm="deleteStaticUser(record)"
                        >
                          <a-button type="danger" size="small">删除</a-button>
                        </a-popconfirm>
                      </div>
                    </a-table>
                    <div class="content-bar auto">
                      <custom-page :total="count" @getPageMes="getPageMes" :hideSize="true" :page="filter.current_page" :limit="filter.page_size"></custom-page>
                    </div>
                  </div>
                  <a-form-model-item label="" :wrapper-col="{offset: 6}" style="margin-top:10px;" align="center">
                    <a-button type="primary" size="small" @click="saveDepartment">保存</a-button>
                  </a-form-model-item>
                </a-form-model>
              </div>
            </div>
          </div>
        </div>
      </a-spin>
    </a-layout-content>
    <a-modal v-model="addVisible" title="添加标签" @cancel="tagClose" @ok="addTagSubmit" width="600px">
      <a-form-model
          id="no-margin-form"
          layout="inline"
          ref="addForm"
          :model="addForm"
          :rules="addRules"
          class="ant-advanced-search-form"
          :label-col="{span: 4}"
          :wrapper-col="{span: 20}"
        >
        <a-form-model-item label="标签名称" prop="name">
          <a-input v-model="addForm.name" placeholder="请输入名称" allowClear size="small" style="width: 312px;" />
        </a-form-model-item>
        <a-form-model-item label="标签类型" prop="type">
          <a-radio-group :options="options" v-model="addForm.type" />
        </a-form-model-item>
        <a-form-model-item label="筛选器" prop="filterid" v-show="addForm.type === 'dynamic'">
          <a-select v-model="addForm.filterid" placeholder="请选择类型" size="small" show-search option-filter-prop="children" :filter-option="filterOption" allowClear style="width: 312px;">
            <a-select-option :value="labelSelectItem.id" v-for="(labelSelectItem, labelSelectIndex) in filterList" :key="labelSelectIndex" v-if="labelSelectItem.type === 'standard'">{{labelSelectItem.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="筛选器" prop="filterid" v-show="addForm.type === 'dynamic_runtime'">
          <a-select v-model="addForm.filterid" placeholder="请选择类型" size="small" show-search option-filter-prop="children" :filter-option="filterOption" allowClear style="width: 312px;">
            <a-select-option :value="labelSelectItem.id" v-for="(labelSelectItem, labelSelectIndex) in filterList" :key="labelSelectIndex" v-if="labelSelectItem.type === 'runtime'">{{labelSelectItem.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal v-model="resultVisible" title="导入完成" @cancel="resultClose" @ok="resultClose" width="434px">
      <div v-if="result.abnormal">
        导入完成，成功{{result.normal}}条，失败{{result.abnormal}}条<span>，请 <a :href="config.api + 'tag/static/user/batch/download/' + resultId + '/abnormal'" target="_blank">下载失败名单</a></span>
      </div>
      <div v-else>
        导入完成，共导入{{result.normal}}条数据
      </div>
      <span slot="footer" class="dialog-footer">
        <a-button type="primary" @click="resultVisible = false">确 定</a-button>
      </span>
    </a-modal>
    <a-modal v-model="confirmVisible" :title="'删除（' + current.name + '）'" :footer="null" width="400px">
      <!-- <p>此标签在以下系统接入中有使用，如确认删除，需在下方输入框内输入此标签名称</p> -->
      <p>标签被占用，有授权中的应用，不能被删除， <a href="javascript:;" style="text-decoration: underline;" class="alink" @click="checkDetail(current)">查看详情</a></p>
      <!-- <p v-for="(item, index) in confirmData" :key="index">
        {{index + 1}}.《{{item.serviceid}}》中的{{item.strategy === 'white' ? '白名单' : '黑名单'}}
      </p> -->
      <div class="delete-input">
        <div class="left">强制删除：</div>
        <div class="right"><a-input v-model="confirmName" placeholder="请输入标签名称" allowClear size="small" /></div>
      </div>
      <p v-show="confirmName && (confirmName != current.name)" class="adelete font12">请输入正确的标签名称</p>

      <div class="confirm-footer auto">
        <a-button type="danger" size="small" @click="confirmSubmit">强制删除</a-button>
        <a-button type="primary" size="small" @click="confirmClose">放弃</a-button>
      </div>
    </a-modal>

    <a-modal v-model="detailVisible" :title="'标签使用详情（' + current.name + '）'" :footer="null" @cancel="detailClose" width="1000px" :zIndex="11111">
      <div class="log-table">
        <a-table :rowKey="(record,index)=>{return index}" :columns="detailTablecolumns" :data-source="detailTabledata" size="middle" :pagination="false">
            <div slot="serviceid" slot-scope="text, record">
              {{serviceList[text]}}
            </div>
            <div slot="strategy" slot-scope="text">
              {{strategyData[text]}}
            </div>
            <div slot="action" slot-scope="text, record">
              <a-popconfirm
                title="确定要删除吗?"
                ok-text="确定"
                cancel-text="取消"
                placement="topRight"
                @confirm="deleteService(record)"
              >
                <a-button type="danger" size="small">删除</a-button>
              </a-popconfirm>
            </div>
          </a-table>
      </div>
    </a-modal>

  </a-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import { Group } from '@/model'
export default {
  data () {
    return {
      confirmName: '',
      confirmData: [],
      confirmVisible: false,
      boxHeight: 0,
      loading: true,
      isStart: false,
      treeData: [],
      searchValue: '',
      itemForm: {
        name: '',
        type: 'static',
        order: '',
        filterid: undefined
      },
      itemRules: {
        name: [
          { required: true, message: '请填写标签名称', trigger: 'blur' }
        ],
        filterid: [
          { required: false, message: '请选择筛选器', trigger: 'blur' }
        ]
      },
      current: {},
      departmentName: {},
      isAdd: false,
      options: [
        {
          value: 'static',
          label: '静态'
        },
        {
          value: 'dynamic',
          label: '动态'
        },
        {
          value: 'dynamic_runtime',
          label: '动态(运行时)'
        }
      ],
      addForm: {
        name: '',
        type: 'static',
        order: 0,
        filterid: undefined
      },
      addRules: {
        name: [
          { required: true, message: '请填写标签名称', trigger: 'blur' }
        ],
        filterid: [
          { required: false, message: '请选择筛选器', trigger: 'blur' }
        ]
      },
      addVisible: false,
      filterList: [],
      tablecolumns: [
        {
          title: '学工号',
          dataIndex: 'schoolid',
          width: 120
        },
        {
          title: '姓名',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
          width: 100
        },
        {
          title: '所属单位',
          dataIndex: 'department',
          scopedSlots: { customRender: 'department' },
          customCell: () => {
            return {
              style: {
                'min-width': '100px',
              }
            }
          }
        }
      ],
      tablecolumns2: [
        {
          title: '学工号',
          dataIndex: 'schoolid',
          width: 120
        },
        {
          title: '姓名',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
          width: 100
        },
        {
          title: '所属单位',
          dataIndex: 'department',
          scopedSlots: { customRender: 'department' },
          customCell: () => {
            return {
              style: {
                'min-width': '100px',
              }
            }
          }
        },
        {
          fixed: 'right',
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
          width: 90
        }
      ],
      tabledata: [],
      count: 0,
      filter: {
        current_page: 1,
        page_size: 10
      },
      schoolid: '',
      showTable: false,
      tableLoading: false,
      statusTimer: null,
      resultId: '',
      resultVisible: false,
      result: {
        total: 0,
        normal: 0,
        abnormal: 0,
        information: ''
      },
      detailVisible: false,
      detailTablecolumns: [
        {
          title: '系统名称号',
          dataIndex: 'serviceid',
          scopedSlots: { customRender: 'serviceid' }
        },
        {
          title: '授权策略',
          dataIndex: 'strategy',
          scopedSlots: { customRender: 'strategy' }
        },
        {
          title: '开始日期',
          dataIndex: 'startDate',
        },
        {
          title: '结束日期',
          dataIndex: 'endDate',
        },
        {
          fixed: 'right',
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
          width: 90
        }
      ],
      detailTabledata: [],
      detailCount: 0,
      detailFilter: {
        current_page: 1,
        page_size: 10
      },
      serviceList: {},
      strategyData: {
        'black': '黑名单',
        'white': '白名单',
        'mfa': '双因素'
      }
    }
  },
  watch: {
    'addForm.type': function (val) {
      if (val === 'dynamic' || val === 'dynamic_runtime') {
        this.addRules['filterid'][0]['required'] = true
      } else {
        this.addRules['filterid'][0]['required'] = false
      }
    },
    'itemForm.type': function (val) {
      if (val === 'dynamic' || val === 'dynamic_runtime') {
        this.itemRules['filterid'][0]['required'] = true
      } else {
        this.itemRules['filterid'][0]['required'] = false
      }
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ])
  },
  mounted () {
    this.boxHeight = document.body.clientHeight - 250
  },
  methods: {
    async deleteService (item) {
      this.loading = true
      let res = await Group.deletetTagIdService(this.current.id, item.serviceid)
      this.loading = false
      if (!res['code']) {
        this.checkDetail(this.current)
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    detailClose () {
      this.detailVisible = false
    },
    async checkDetail (item) {
      this.current = item
      this.loading = true
      let res = await Group.getTagUsed(item.id)
      this.loading = false
      if (!res['code']) {
        this.detailVisible = true
        if (res['data']) {
          this.detailTabledata = res['data']
          this.detailCount = res['data'].length
        } else {
          this.detailTabledata = []
          this.detailCount = 0
        }
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    async deleteStaticUser (row) {
      this.tableLoading = true
      let res = await Group.deleteTagUser(this.current.id, row.userid)
      this.tableLoading = false
      if (!res['code']) {
        this.$message.success('删除成功！')
        this.getStaticUserList()
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    handleChange(info) {
      const status = info.file.status;
      // if (status !== 'uploading') {
      //   console.log(info.file, info.fileList);
      // }
      if (status === 'done') {
        // console.log(info)
        if (!info.file.response['code']) {
          this.tableLoading = true
          this.statusTimer = setInterval(() => {
            this.resultId = info.file.response['data']
            this.getUploadStatus(info.file.response['data'])
          }, 1000)
        } else {
          // this.$message.error(info.file.response['description'])
          this.$message.error(this.errMsg[info.file.response['code']])
        }
      } else if (status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`);
      }
    },
    async getUploadStatus (id) {
      let res = await Group.getUploadStatus(id)
      if (!res['code']) {
        if (res['data'] === 'interrupt') {
          this.tableLoading = false
          this.$message.error('执行中断');
          clearInterval(this.statusTimer)
        }
        if (res['data'] === 'complete') {
          this.tableLoading = false
          clearInterval(this.statusTimer)
          this.getUploadResult(id)
          this.getStaticUserList()
        }
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    resultClose () {
      this.resultVisible = false
    },
    async getUploadResult (id) {
      let res = await Group.getUploadResult(id)
      if (!res['code']) {
        this.result = res['data']
        this.resultVisible = true
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async getStaticUserList () {
      this.tableLoading = true
      this.showTable = true
      let res = await Group.getStaticUser(this.current.id, this.filter)
      this.tableLoading = false
      if (!res['code']) {
        this.tabledata = res['data']
        this.count = res['total']
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    download () {
      // console.log('http:' + this.config.api + 'tag/static/template')
      window.open('//' + this.config.api + 'tag/static/template', '_blank')
    },
    async addUser () {
      if (!this.schoolid) {
        this.$message.error('请输入学工号！')
        return false
      }
      this.loading = true
      let res = await Group.addTagUser(this.current.id, this.schoolid)
      this.loading = false
      if (!res['code']) {
        this.$message.success('添加成功！')
        this.getStaticUserList()
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    getPageMes (obj) {
      this.filter = {
        current_page: obj.pageNumber,
        page_size: obj.pageSize
      }
      if (this.itemForm.type === 'static') {
        this.getStaticUserList()
      } else {
        this.getUserList()
      }
    },
    prviewUsers () {
      if (this.itemForm.type === 'static') {
        this.getStaticUserList()
      } else {
        this.getUserList()
      }
    },
    async getUserList () {
      this.tableLoading = true
      this.showTable = true
      let res = await Group.getDynamicUser(this.current.id, this.filter)
      this.tableLoading = false
      if (!res['code']) {
        this.tabledata = res['data']
        this.count = res['total']
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    editFilter () {
      if (this.itemForm.filterid) {
        this.$router.push({
          path: '/group/filters',
          query: {
            id: this.itemForm.filterid
          }
        })
      } else {
        this.$router.push('/group/filters')
      }
    },
    tagClose () {
      this.$refs.addForm.resetFields()
      this.addVisible = false
      this.addForm = {
        name: '',
        type: 'static',
        order: 0,
        filterid: undefined
      }
    },
    addTag () {
      this.addVisible = true
    },
    async addTagSubmit () {
      this.$refs['addForm'].validate( async validate => {
        if (validate) {
          this.loading = true
          let res = await Group.addTag(this.addForm)
          this.loading = false
          if (!res['code']) {
            this.$message.success('添加成功！')
            this.tagClose()
            this.getDepartment()
          } else {
            this.$message.error(this.errMsg[res['code']])
          }
        }
      })
    },
    async chooseList (row) {
      this.init()
      this.isStart = true
      this.isAdd = false
      this.current = row
      this.loading = true
      let res = await Group.getTagDetail(row.id)
      this.loading = false
      if (!res['code']) {
        this.itemForm = {
          name: res['data']['name'],
          type: res['data']['type'],
          order: res['data']['order'],
          filterid: res['data']['filterid'] ? Number(res['data']['filterid']) : undefined
        }
        if (res['data']['type'] === 'static') {
          this.getStaticUserList()
        }
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async deleteHandle (row) {
      this.current = row
      this.loading = true
      let res = await Group.getTagUsed(this.current.id)
      this.loading = false
      if (!res['code']) {
        if (res['data']) {
          this.confirmData = res['data']
          this.confirmVisible = true
          this.confirmData = []
          this.confirmName = ''
        } else {
          this.deleteHandleFn(row)
        }
        // if (row.id === this.current.id) {
        //   this.init()
        // }
        // this.$message.success('删除成功')
        // this.getDepartment()
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async deleteHandleFn (row) {
      this.loading = true
      // let res = await Group.deleteTag(this.current.id, {
      //   name: this.confirmName
      // })
      let res = await Group.deleteTag(this.current.id + '?name=' + this.confirmName)
      this.loading = false
      if (!res['code']) {
        if (row.id === this.current.id) {
          this.init()
        }
        this.$message.success('删除成功')
        this.getDepartment()
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    confirmClose () {
      this.confirmVisible = false
    },
    confirmSubmit () {
      if (this.current.name != this.confirmName) return false
      this.confirmClose()
      this.deleteHandleFn(this.current)
    },
    init () {
      this.showTable = false
      this.isStart = false
      this.itemForm = {
        name: '',
        type: 'static',
        order: '',
        filterid: undefined
      }
      this.count = 0
      this.filter = {
        current_page: 1,
        page_size: 10
      }
      this.tabledata = []
    },
    async sortDepartment () {
      this.loading = true
      let res = await Group.sortUserType(this.treeData)
      this.loading = false
      if (!res['code']) {
        this.$message.success('操作成功！')
        this.getDepartment()
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async saveDepartment () {
      this.$refs['itemForm'].validate( async validate => {
        if (validate) {
          this.loading = true
          let res = await Group.changeTag(this.current.id, {
            name: this.itemForm.name,
            type: this.itemForm.type,
            order: Number(this.itemForm.order),
            filterid: this.itemForm.filterid
          })
          this.loading = false
          if (!res['code']) {
            this.$message.success('保存成功')
            this.getDepartment()
          } else {
            this.$message.error(this.errMsg[res['code']])
          }
        }
      })
    },
    async getDepartment () {
      let res = await Group.getTag('usertype')
      if (!res['code']) {
        this.treeData = res['data']
        // console.log(res['data'])
      }
    },
    async getFilterList () {
      let res = await Group.getFilterList()
      if (!res['code']) {
        this.filterList = res['data']
      }
    },
    trunData (data) {
      for (let k in data) {
        this.departmentName[data[k]['id']] = data[k]['name']
        if (data[k]['children']) {
          this.trunData(data[k]['children'])
        }
      }
    },
    async getDepartmentNames () {
      this.loading = true
      let res = await Group.getDepartment()
      this.loading = false
      if (!res['code']) {
        this.trunData(res['data'])
      }
    },
    async getCode () {
      let res = await Group.getCode('service')
      if (!res['code']) {
        let list = {}
        res['data'].map(item => {
          list[item['id']] = item['name']
        })
        this.serviceList = list
      }
    }
  },
  created () {
    this.getDepartment()
    this.getFilterList()
    this.getDepartmentNames()
    this.getCode()
  }
}
</script>

<style scoped>
.drawer-bar {
  padding: 8px 0 20px;
}

.depart-box {
  width: 49%;
}
.depart-l {
  border-right: 1px solid rgba(0,0,0,0.06);
  padding-right: 20px;
}
.depart-title {
  width: 100%;
  height: 30px;
  line-height: 30px;
  background: rgba(0,0,0,0.02);;
  border-bottom: 1px solid rgba(0,0,0,0.06);
}
.depart-title-l {
  margin-left: 24px;
}
.depart-title-r {
  margin-right: 24px;
}
.depart-title-r span {
  margin-left: 15px;
  color: #0091FF;
  font-size: 12px;
}

.tree-node .fr {
  color: #1890FF;
  margin-right: 10px;
  display: none;
}
.ant-tree-node-content-wrapper:hover .fr {
  display: block;
}
.tree-node .fr i {
  margin-right: 10px;
}

.department-content {
  width: 70%;
  min-width: 910px;
}
.ant-form-item {
  width: 100%;
}
.right-form {
  margin-top: 5px;
}

.depart-con {
  width: 100%;
}
.left-con {
  margin-top: 10px;
  overflow-y: auto;
}
.left-con ul li {
  line-height: 28px;
  padding: 0 25px 0 2px;
  cursor: pointer;
  width: 100%;
  overflow: hidden;
}
.left-con ul li i {
  display: none
}
.left-con ul li.cur {
  background: #E6F7FF;
}
.left-con ul li:hover {
  background: #E6F7FF;
}
.left-con ul li:hover i {
  display: block;
  margin-top: 8px;
  color: #1890FF;
}
.left-con ul li i.dynamic-icon {
  margin-top: 8px;
  margin-right: 10px;
  display: block;
  color: #fdb600;
}
.left-con ul li:hover i.dynamic-icon {
  display: block;
  color: #fdb600;
}
.left-con ul li i.static-icon {
  color: #57ce00;
}
.left-con ul li:hover i.static-icon {
  color: #57ce00;
}
.left-con ul li span.fl {
  display: block;
  max-width: 80%;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: auto;
  overflow: hidden;
}
.left-con ul li .detail {
  margin-right: 10px;
}

.tag-btns {
  padding: 5px 40px 5px 0px;
}
.tag-table {
  padding: 5px 38px 5px 30px; 
}
.content-bar {
  padding: 10px 0;
}

.delete-input {
  display: flex;
}
.delete-input .left {
  width: 80px;
}
.delete-input .right {
  flex: 1;
}
.confirm-footer {
  margin-top: 30px;
  text-align: right;
  width: 100%;
  border-top: 1px solid rgba(0,0,0,0.06);
  padding-top: 20px;
}
.confirm-footer button {
  margin-left: 10px;
}
</style>
