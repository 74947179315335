<template>
  <a-layout class="custom-layout">
    <a-layout-sider class="custom-sider">
      <a-button class="back-btn" size="small" @click="onBack"><a-icon type="double-left" class="font12" />返回</a-button>
      <div class="batch-menu">
        <ul>
          <li :class="menuIndex === 1 && 'cur'" @click="changeMenu(1)" v-if="type === 'add'">添加应用</li>
          <li :class="menuIndex === 2 && 'cur'" @click="changeMenu(2)" v-if="type === 'edit' || type === 'data'">编辑应用</li>
          <li :class="menuIndex === 3 && 'cur'" @click="changeMenu(3)" v-if="type === 'data'">统计应用</li>
        </ul>
      </div>
    </a-layout-sider>
    <a-layout-content class="custom-content auto" style="position:relative;" v-if="menuIndex !== 3">
      <a-spin :spinning="loading">
        <div class="drawer-bar auto">
          <span class="font16"><b>{{menuIndex === 1 ? '添加应用' : menuIndex === 2 ? '编辑应用' : '统计应用'}}</b></span>
        </div>
        <div class="department-content auto">
          <div class="form-box auto" v-if="menuIndex === 1 || menuIndex === 2" style="width: 907px;">
            <a-form-model
                id="no-margin-form"
                ref="ruleForm"
                :model="form"
                :rules="rules"
                class="ant-advanced-search-form"
                :label-col="{span: 3}"
                :wrapper-col="{span: 21}"
              >
              <a-form-model-item label="应用名称" prop="name">
                <a-input v-model="form.name" placeholder="请输入应用名称" size="small" style="width:475px;" />
              </a-form-model-item>
              <a-form-model-item label="appid" prop="appid">
                <a-input v-model="form.appid" placeholder="APPID" :disabled="true" size="small" style="width:475px;" />
              </a-form-model-item>
              <a-form-model-item label="appsecret" prop="appsecret">
                <a-input v-model="form.appsecret" placeholder="AppSECRET" :disabled="true" size="small" style="width:475px;" class="m-r-5" />
                <a-button type="primary" size="small" @click="getAppGenerate">获取</a-button>
              </a-form-model-item>
              <a-form-model-item label="安全IP" prop="securityAccessIp">
                <a-input v-model="form.securityAccessIp" placeholder="请输入安全IP（支持正则表达式）" size="small" style="width:475px;" />
              </a-form-model-item>
              <a-form-model-item label="接口权限" prop="authorization">
                <div class="form-tips auto">
                  你可以勾选多个接口权限，使此应用可以请求已勾选的接口服务
                </div>
                <div class="form-content auto">
                  <div class="auto" v-for="(item, index) in form.authorization" :key="index">
                    <div v-if="item.serviceType === 'user' || item.children" :class="index !== form.authorization.length - 1 ? 'form-content-box auto form-content-box1' : 'form-content-box auto form-content-box2'">
                      <div class="form-box-title auto font16 grey999">{{item.name}}</div>
                      <div class="form-box-con auto">
                        <a-row type="flex">
                          <a-col flex="0 0 80px">{{item.alias}}：</a-col>
                          <a-col flex="1 1 300px">
                            <a-checkbox v-if="form.authorization[index]['children']" v-model="form['authorization'][index]['checkedAll']" @change="onCheckAllChange($event, index)" style="margin-left:0;margin-right:10px;">全选</a-checkbox>
                            <ul class="jk-list">
                              <li v-for="(checkBoxItem, checkBoxIndex) in item.children" :key="checkBoxIndex">
                                <a-checkbox style="margin-left:0;margin-right:10px;min-width:160px;" v-model="form.authorization[index]['children'][checkBoxIndex]['selected']" @change="onCheckBoxChange($event, index)">{{checkBoxItem.name}}</a-checkbox>
                                <a-button type="primary" v-show="form.authorization[index]['children'][checkBoxIndex]['fields']" size="small" style="margin-left:0px;margin-right:10px;" @click="showKeys(index, checkBoxIndex, form.authorization[index]['children'][checkBoxIndex])" :disabled="!form.authorization[index]['children'][checkBoxIndex]['selected']">返回字段</a-button>
                                <span v-show="form.authorization[index]['children'][checkBoxIndex]['fields'] && form.authorization[index]['children'][checkBoxIndex]['selected']">已选：{{getChoosed(form.authorization[index]['children'][checkBoxIndex])}}个</span>
                              </li>
                            </ul>
                          </a-col>
                        </a-row>
                        <a-row class="m-t-5" v-if="item.serviceType === 'user'">
                          <a-col :span="3">人员范围：</a-col>
                          <a-col :span="21">
                            <a-button type="primary" size="small" class="fl" style="margin-left:-20px;margin-right:10px;" @click="visibleOpen(index)">选择成员</a-button>
                            <div class="fl grey999">
                              已选：
                              <!-- {{'tags：' + form.authorization[index]['tags']}} <br>
                              {{'scopes：' + form.authorization[index]['scopes']}} <br> -->
                              <span v-if="form.authorization[index]['tags'] && form.authorization[index]['tags'].length">
                                <span v-for="(tagItem, tagIndex) in form.authorization[index]['tags']" :key="tagIndex">
                                  {{tagsName[tagItem]}}
                                  <span v-if="tagIndex < form.authorization[index]['tags'].length - 1">,</span>
                                </span>；
                              </span>
                              <span v-if="form.authorization[index]['scopes'] && form.authorization[index]['scopes'].length">
                                <span v-for="(scopesItem, scopesIndex) in form.authorization[index]['scopes']" :key="scopesIndex">
                                  <span>{{departmentName[scopesItem.depart]}}</span><span v-if="departmentName[scopesItem.depart] && scopesItem['type']">-</span>
                                  <span v-for="(typeItem, typeIndex) in scopesItem['type']" :key="typeIndex">
                                    {{userTypeName[typeItem]}}
                                    <span v-if="typeIndex < scopesItem['type'].length - 1">,</span>
                                  </span>；
                                </span>
                              </span>
                            </div>
                          </a-col>
                        </a-row>
                      </div>
                    </div>
                  </div>
                </div>
              </a-form-model-item>
              <a-form-model-item label="应用描述" prop="description">
                <a-input type="textarea" v-model="form.description" placeholder="请输入描述" size="small" style="width:475px;" />
              </a-form-model-item>
              <a-form-model-item label="启用状态" prop="enabled">
                <a-switch v-model="form.enabled" size="small" />
              </a-form-model-item>
              <a-form-model-item label="" prop="" :wrapper-col="{offset: 3}" class="m-t-10">
                <a-button type="primary" size="small" @click="submitApp">保存</a-button>
              </a-form-model-item>
            </a-form-model>
          </div>
        </div>
      </a-spin>
    </a-layout-content>
    <a-layout-content class="custom-content auto" style="position:relative;" v-else>
      <a-spin :spinning="loading">
        <div class="drawer-bar auto">
          <div class="fl">
            <span class="font16"><b>统计应用（{{form.name}}）</b></span>
          </div>
          <div class="fr">
            <custom-date-picker v-model="date" @getDate="getDate"></custom-date-picker>
          </div>
        </div>
        <div class="department-content auto">
          <div class="box auto">
            <div class="box-title auto">
              <div class="fl">各API调用量（Top 10）</div>
            </div>
            <div class="box-con auto">
              <div style="width:500px;height:300px;">
                <echarts-base :data="chartData" height="300"></echarts-base>
              </div>
            </div>
          </div>
          <div class="box box2 auto">
            <div class="box-title auto">
              <div class="fl">各API调用统计</div>
            </div>
            <div class="box-con auto no-bor">
              <div class="m-t-10">
                <a-table :rowKey="(record,index)=>{return index}" :columns="tablecolumns" :data-source="tabledata" size="middle" :pagination="false" @change="changeTable">
                  <span slot="index" slot-scope="text,record,index">{{ index + (filter.page_size * (filter.current_page - 1)) + 1 }}</span>
                  <div slot="action" slot-scope="text, record">
                    <a-button size="small" @click="detailHandle(record)">查看</a-button>
                  </div>
                </a-table>
              </div>
              <div class="m-t-10">
                <custom-page :total="count" @getPageMes="getPageMes" :page="filter.current_page" :limit="filter.page_size"></custom-page>
              </div>
            </div>
          </div>
        </div>
      </a-spin>
    </a-layout-content>
    <a-modal v-model="visible" title="人员范围" @ok="visibleClose" width="900px">
      <a-form-model
          id="no-margin-form2"
          layout="inline"
          ref="dialogForm"
          class="ant-advanced-search-form"
          labelAlign="left"
          :label-col="{span: 2}"
          :wrapper-col="{span: 21}"
        >
        
        <p>标签与机构同时选择时取并集</p>
        <a-form-model-item label="标签" prop="name" style="width:100%;">
          <el-cascader v-if="form.authorization[current_index] && form.authorization[current_index]['tags']" placeholder="请选择管理对象" v-model="form.authorization[current_index]['tags']" :show-all-levels="false" :props="{ label: 'name', value: 'id', children: 'children', multiple: true, emitPath: false }" :options="tagOptions" size="mini" style="width:108%;margin-left: -23px;" class="custom-el-cascader" clearable></el-cascader>
        </a-form-model-item>
        <div class="add-btn m-t-10">
          <a-button type="dashed" style="width:100%;" @click="addItem">+ 添加权限</a-button>
        </div>
        <div class="auto" v-if="form.authorization[current_index] && form.authorization[current_index]['scopes']">
          <div class="add-item" v-for="(item, index) in form.authorization[current_index]['scopes']" :key="index">
            <el-cascader placeholder="请选择管理单位" v-model="form.authorization[current_index]['scopes'][index]['depart']" :show-all-levels="false" :props="{ checkStrictly: true, label: 'name', value: 'id', children: 'children', emitPath: false }" :options="filters['department']" size="mini" style="width:30%;margin-right:5px;" id="custom-el-cascader3" clearable></el-cascader>
            <el-cascader placeholder="请选择管理对象" v-model="form.authorization[current_index]['scopes'][index]['type']" :show-all-levels="false"  :props="{ checkStrictly: true, label: 'name', value: 'id', children: 'children', multiple: true, emitPath: false }" :options="filters['usertype']" size="mini" style="width:60%;margin-right:5px;" id="custom-el-cascader4" clearable></el-cascader>
            <a href="javascript:;" class="font16" @click="deleteItem(index)"><a-icon type="delete" /></a>
             <!-- v-if="form.authorization[current_index]['scopes'].length > 1" -->
          </div>
        </div>
      </a-form-model>
    </a-modal>
    <a-modal v-model="detailVisible" title="详情" @ok="detailClose" width="710px">
      <div>
        <span style="margin-right:35px;">API名称：{{current_row.apiname}}</span>
        <span>API类别：{{current_row.apiTypeName}}</span>
      </div>
      <div class="auto m-t-10">
        <div class="fr">
          <custom-date-picker v-model="date2" @getDate="getDate2"></custom-date-picker>
        </div>
      </div>
      <div class="m-t-10">
        <a-table :rowKey="(record,index)=>{return index}" :columns="detailTableHeader" :data-source="detailTableData" size="middle" :pagination="false" @change="changeTable2">
          <span slot="index" slot-scope="text,record,index">{{ index + (filter2.page_size * (filter2.current_page - 1)) + 1 }}</span>
        </a-table>
      </div>
      <div class="p-tb-10">
        <custom-page :total="count2" @getPageMes="getPageMes2" :page="filter2.current_page" :limit="filter2.page_size"></custom-page>
      </div>
    </a-modal>
    <a-modal v-model="keysVisible" title="返回字段" @ok="keysSubmit" @cancel="keysCancel" width="800px">
      <template slot="footer">
        <a-button key="back" @click="keysCancel">
          取 消
        </a-button>
        <a-button key="submit" type="primary" @click="keysSubmit">
          确 定
        </a-button>
      </template>
      <ul class="auth-box-ul">
        <li v-for="(item, index) in currentKeys" :key="index">
          <a-checkbox v-model="form.authorization[keyIndex]['children'][checkBoxIndex]['fields'][index]['selected']">
            {{item.name}}
          </a-checkbox>
        </li>
      </ul> 
    </a-modal>
  </a-layout>
</template>

<script>
import { System } from '@/model'
import { Data as DataController } from '@/controller'
export default {
  data () {
    return {
      type: 'add',
      currentId: '',
      currentAppId: '',
      filters: {},
      menuIndex: 1,
      openapi: [],
      tagOptions: [],
      departmentName: {},
      userTypeName: {},
      tagsName: {},
      current_index: 0,
      form: {
        appid: '',
        name: '',
        appsecret: '',
        securityAccessIp: '',
        authorization: [],
        description: '',
        enabled: true
      },
      rules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        appid: [
          { required: true, message: '请生成APPID', trigger: 'blur' }
        ],
        appsecret: [
          { required: true, message: '请生成APPSecret', trigger: 'blur' }
        ],
        securityAccessIp: [
          { required: true, message: '请输入安全IP', trigger: 'blur' }
        ]
      },
      checkAll: false,
      plainOptions: ['Apple', 'Pear', 'Orange'],
      visible: false,
      date: [],
      chartData: '',
      tablecolumns: [
        {
          title: '序号',
          dataIndex: 'index',
          scopedSlots: { customRender: 'index' },
          key: 'index'
        },
        {
          title: 'API名称',
          dataIndex: 'apiname',
          key: 'apiname'
        },
        {
          title: 'API类别',
          dataIndex: 'apiTypeName',
          key: 'apiTypeName'
        },
        {
          title: '调用次数',
          dataIndex: 'api_count',
          sorter: true,
          scopedSlots: { customRender: 'api_count' },
          key: 'api_count',
          sortDirections: ['ascend', 'descend']
        },
        // {
        //   title: '服务应用数',
        //   dataIndex: 'app_count',
        //   sorter: true,
        //   scopedSlots: { customRender: 'app_count' },
        //   key: 'app_count',
        //   sortDirections: ['ascend', 'descend']
        // },
        // {
        //   title: '详情',
        //   dataIndex: 'action',
        //   scopedSlots: { customRender: 'action' },
        //   key: 'action'
        // }
      ],
      tabledata: [],
      filter: {
        page_size: 10,
        current_page: 1,
        orderApi: '',
        orderApp: ''
      },
      count: 0,
      date2: [],
      filter2: {
        page_size: 10,
        current_page: 1,
        order: '',
      },
      count2: 0,
      current_row: '',
      detailVisible: false,
      detailTableHeader: [
        {
          title: '序号',
          dataIndex: 'index',
          scopedSlots: { customRender: 'index' },
          key: 'index'
        },
        {
          title: '应用名称',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: '调用次数',
          dataIndex: 'count',
          key: 'count',
          sorter: true,
          sortDirections: ['ascend', 'descend']
        }
      ],
      detailTableData: [
        {
          index: 1,
          schoolid: '获取单个用户照片',
          schoolid2: '人脸照片',
          schoolid3: '1,229',
          schoolid4: '32'
        }
      ],
      loading: false,
      currentKeys: [],
      currentKeysOrigin: [],
      keyIndex: 0,
      checkBoxIndex: 0,
      keysVisible: false
    }
  },
  computed: {
    // treeData (val) {
    //   return handleTreeData(val, this.targetKeys);
    // }
  },
  methods: {
    keysSubmit () {
      this.keysVisible = false
    },
    keysCancel () {
      this.form.authorization[this.keyIndex]['children'][this.checkBoxIndex]['fields'] = this.currentKeysOrigin
      this.keysVisible = false
    },
    showKeys (index, checkBoxIndex, val) {
      this.keyIndex = index
      this.checkBoxIndex = checkBoxIndex
      this.keysVisible = true
      this.currentKeys = val.fields
      this.currentKeysOrigin = JSON.parse(JSON.stringify(val.fields))
    },
    getChoosed (val) {
      let count = 0
      if (val.fields && val.fields.length) {
        for (let key in val.fields) {
          if (val.fields[key]['selected']) {
            count++
          }
        }
      }
      return count
    },
    submitApp () {
      this.$refs['ruleForm'].validate( async validate => {
        if (validate) {
          let form = JSON.parse(JSON.stringify(this.form))
          if (form.authorization.length) {
            form.authorization.map(item => {
              delete item['checkedAll']
            })
          }
          form['enabled'] = form['enabled'] ? 1 : 0
          let res = null
          this.loading = true
          if (this.type === 'edit' || this.type === 'data') {
            res = await System.changeApp(this.currentId, form)
          } else {
            res = await System.addApp(form)
          }
          this.loading = false
          if (!res['code']) {
            this.onBack()
            this.$message.success('操作成功！')
          } else {
            this.$message.error(this.errMsg[res['code']])
          }
        }
      })
    },
    deleteItem (index) {
      this.form.authorization[this.current_index]['scopes'].splice(index, 1)
    },
    addItem () {
      this.form.authorization[this.current_index]['scopes'].push({
        depart: undefined,
        type: undefined
      })
    },
    onCheckBoxChange (event, index) {
      let status = true
      for (let key in this.form['authorization'][index]['children']) {
        if (!this.form['authorization'][index]['children'][key]['selected']) {
          status = false
          break
        }
      }
      this.form['authorization'][index]['checkedAll'] = status
      this.form = JSON.parse(JSON.stringify(this.form))
    },
    async getAppGenerate () {
      let type = 'all'
      if (this.type === 'add') {
        type = 'all'
      } else {
        type = 'appsecret'
      }
      this.loading = true
      let res = await System.getAppGenerate(type)
      this.loading = false
      if (!res['code']) {
        if (this.type === 'add') {
          this.form.appid = res['data']['appid']
          this.form.appsecret = res['data']['appsecret']
        } else {
          this.form.appsecret = res['data']['appsecret']
        }
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    detailClose () {
      this.filter2.page_size = 10
      this.filter2.current_page = 1
      this.detailVisible = false
    },
    detailHandle (row) {
      this.detailVisible = true
      this.current_row = row
      this.getAppStatisticListDetail()
    },
    changeTable (pagination, filters, sorter, { currentDataSource }) {
      this.filter['current_page'] = 1
      if (sorter.order) {
        var word = sorter.order === 'ascend' ? 'asc' : 'desc'
        if (sorter.columnKey === 'api_count') {
          this.filter['orderApp'] = ''
          this.filter['orderApi'] = word
        } else {
          this.filter['orderApp'] = word
          this.filter['orderApi'] = ''
        }
      } else {
        this.filter['orderApp'] = ''
        this.filter['orderApi'] = ''
      }
      this.getAppStatisticList()
    },
    changeTable2 (pagination, filters, sorter, { currentDataSource }) {
      this.filter2['current_page'] = 1
      if (sorter.order) {
        var word = sorter.order === 'ascend' ? 'asc' : 'desc'
        this.filter2['order'] = word
      } else {
        this.filter2['order'] = ''
      }
      this.getAppStatisticListDetail()
    },
    getDate (date) {
      this.date = [date[0], date[1]]
      this.filter = {
        page_size: 10,
        current_page: 1,
        orderApi: '',
        orderApp: ''
      }
      this.getAppStatistic()
      this.getAppStatisticList()
    },
    getDate2 (date) {
      this.date2 = [date[0], date[1]]
      this.filter2 = {
        page_size: 10,
        current_page: 1,
        order: '',
      }
      this.getAppStatisticListDetail()
    },
    filterOption(inputValue, option) {
      // console.log(option.title.indexOf(inputValue) > -1)
      return option.title.indexOf(inputValue) > -1;
    },
    onChange(targetKeys) {
      // console.log('Target Keys:', targetKeys);
      this.targetKeys = targetKeys;
    },
    onChecked(_, e, checkedKeys, itemSelect) {
      const { eventKey } = e.node;
      itemSelect(eventKey, !isChecked(checkedKeys, eventKey));
    },
    visibleOpen (index) {
      this.current_index = index
      this.visible = true
    },
    visibleClose () {
      this.visible = false
    },
    onCheckAllChange (event, index) {
      if (event.target.checked) {
        this.form.authorization[index].children.map((item, index) => {
          item.selected = true
        })
      } else {
        this.form.authorization[index].children.map((item, index) => {
          item.selected = false
        })
      }
      this.form = JSON.parse(JSON.stringify(this.form))
    },
    onBack () {
      this.$router.go(-1)
    },
    changeMenu (index) {
      this.menuIndex = index
      // if (index === 1) {
      // }
      // if (index === 2) {
      // }
      // if (index === 3) {
      //   setTimeout(() => {
      //     this.setChart()
      //   })
      // }
    },
    async getAppDetail () {
      this.loading = true
      let res = await System.getAppDetail(this.currentId)
      this.loading = false
      if (!res['code']) {
        let old = {}
        let oldAll = {}
        for (let key in res['data']['authorization']) {
          let item = res['data']['authorization'][key]
          old[item.code] = []
          oldAll[item.code] = item
          for (let k in item['children']) {
            if (item['children'][k]['selected']) {
              old[item.code].push(item['children'][k]['code'])
            }
          }
        }
        // console.log('old', old)
        // console.log('oldAll', oldAll)
        let openapi = JSON.parse(JSON.stringify(this.openapi))
        // console.log('openapi', openapi)
        for (let key in openapi) {
          for (let k in openapi[key]['children']) {
            let oldItem = old[openapi[key]['code']]
            // console.log('old[openapi[key][code]]', old[openapi[key]['code']])
            if (!oldItem) {
              oldItem = []
              oldAll[openapi[key]['code']] = JSON.parse(JSON.stringify(openapi[key]))
              if (oldAll[openapi[key]['code']]['scopes']) oldAll[openapi[key]['code']]['scopes'] = []
              for (let _k in oldAll[openapi[key]['code']]['children']) {
                oldAll[openapi[key]['code']]['children'][_k]['selected'] = false
                for (let __k in oldAll[openapi[key]['code']]['children'][_k]['fields']) {
                  oldAll[openapi[key]['code']]['children'][_k]['fields'][__k]['selected'] = false
                }
              }
              // break
              // continue
            }
            // console.log('oldItem', oldItem)
            // console.log('openapi', openapi)
            // console.log('key', key)
            let oldAllItem = oldAll[openapi[key]['code']]
            // console.log('111', oldItem)
            // console.log('222', openapi[key]['children'][k]['code'])
            if (oldItem.indexOf(openapi[key]['children'][k]['code']) > -1) {
              openapi[key]['children'][k]['selected'] = true
              if (openapi[key]['children'][k]['fields'] && openapi[key]['children'][k]['fields'].length) {
                for (let _k in openapi[key]['children'][k]['fields']) {
                  let kcode = openapi[key]['children'][k]['fields'][_k]['code']
                  // console.log('333', oldAllItem['children'])
                  // console.log('333444', k)
                  // console.log('444', oldAllItem['children'][k])
                  if (oldAllItem['children'][k] && oldAllItem['children'][k]['fields']) {
                    let field = oldAllItem['children'][k]['fields'].find((fieldItem) => {
                      return fieldItem.code === kcode
                    })
                    if (field && field['selected']) {
                      openapi[key]['children'][k]['fields'][_k]['selected'] = true
                    } else {
                      openapi[key]['children'][k]['fields'][_k]['selected'] = false
                    }
                  }
                }
              }
            } else {
              openapi[key]['children'][k]['selected'] = false
            }
            openapi[key]['scopes'] = oldAll[openapi[key]['code']]['scopes']
            openapi[key]['tags'] = oldAll[openapi[key]['code']]['tags']
          }
        }
        // console.log('000000', openapi)
        this.form = {
          appid: res['data']['appid'],
          name: res['data']['name'],
          appsecret: res['data']['appsecret'],
          securityAccessIp: res['data']['securityAccessIp'],
          // authorization: res['data']['authorization'],
          authorization: openapi,
          description: res['data']['description'],
          enabled: res['data']['enabled'] ? true : false
        }
        // console.log(this.form)
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async getAppStatistic () {
      this.loading = true
      let res = await System.getAppStatistic(this.currentId, {
        startDate: this.date[0],
        endDate: this.date[1]
      })
      this.loading = false
      if (!res['code']) {
        // console.log(res)
        this.chartData = DataController.System.Pie8(res['data'].length ? res['data'] : '')
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async getAppStatisticListDetail () {
      this.loading = true
      let res = await System.getAppStatisticListDetail(this.current_row.apiid, {
        startDate: this.date2[0],
        endDate: this.date2[1],
        order: this.filter2.order,
        current_page: this.filter2.current_page,
        page_size: this.filter2.page_size
      })
      this.loading = false
      if (!res['code']) {
        this.detailTableData = res['data']
        this.count2 = res['total']
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async getAppStatisticList () {
      this.loading = true
      let res = await System.getAppStatisticList(this.currentId, {
        startDate: this.date[0],
        endDate: this.date[1],
        orderApi: this.filter.orderApi,
        orderApp: this.filter.orderApp,
        current_page: this.filter.current_page,
        page_size: this.filter.page_size
      })
      this.loading = false
      if (!res['code']) {
        this.tabledata = res['data']
        this.count = res['total']
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    getPageMes (obj) {
      this.filter.current_page = obj.pageNumber
      this.filter.page_size = obj.pageSize
      this.getAppStatisticList()
    },
    getPageMes2 (obj) {
      this.filter2.current_page = obj.pageNumber
      this.filter2.page_size = obj.pageSize
      this.getAppStatisticListDetail()
    },
    trunDepartmentData (data) {
      for (let k in data) {
        this.departmentName[data[k]['id']] = data[k]['name']
        if (data[k]['children']) {
          this.trunDepartmentData(data[k]['children'])
        }
      }
    },
    trunTagsData (data) {
      for (let k in data) {
        this.tagsName[data[k]['id']] = data[k]['name']
        if (data[k]['children']) {
          this.trunTagsData(data[k]['children'])
        }
      }
    },
    trunUserTypeData (data) {
      for (let k in data) {
        this.userTypeName[data[k]['id']] = data[k]['name']
        if (data[k]['children']) {
          this.trunUserTypeData(data[k]['children'])
        }
      }
    },
    async getDepartment () {
      // let res = await System.getCode('department')
      // this.filters['department'] = res['data']
      // this.trunDepartmentData(res['data'])
      let res = await System.getCode('department')
      this.filters['department'] = res['data']
      this.trunDepartmentData(res['data'])
      let res3 = await System.getCode('usertype')
      this.filters['usertype'] = res3['data']
      this.trunUserTypeData(res3['data'])
      let res1 = await System.getCode('tag')
      this.tagOptions = res1.data
      this.trunTagsData(res1['data'])
      let res2 = await System.getCode('openapi')
      this.openapi = res2['data']
      // this.openapi = [
      //   {
      //     "code": "M01",
      //     "name": "人员管理",
      //     "tags": [
      //       "1"
      //     ],
      //     "alias": "人员接口",
      //     "serviceType": "user",
      //     "category": "serviceType",
      //     "children": [
      //       {
      //         "code": "M0101",
      //         "name": "获取人员数据",
      //         "category": "serviceName",
      //         "selected": true
      //       },
      //       {
      //         "code": "M0102",
      //         "name": "获取单个人员数据",
      //         "category": "serviceName",
      //         "selected": true
      //       },
      //       {
      //         "code": "M0103",
      //         "name": "添加人员数据",
      //         "category": "serviceName"
      //       },
      //       {
      //         "code": "M0104",
      //         "name": "删除人员数据",
      //         "category": "serviceName"
      //       },
      //       {
      //         "code": "M0105",
      //         "name": "修改人员数据",
      //         "category": "serviceName"
      //       }
      //     ],
      //     "scopes": [
      //       {
      //         "depart": "000000",
      //         "type": []
      //       }
      //     ]
      //   },
      //   {
      //     "code": "M12",
      //     "name": "服务管理",
      //     "alias": "服务接口",
      //     "category": "serviceType",
      //     "children": [
      //       {
      //         "code": "M1201",
      //         "name": "获取服务列表",
      //         "category": "serviceName",
      //         "selected": true
      //       },
      //       {
      //         "code": "M1202",
      //         "name": "获取服务详情",
      //         "category": "serviceName"
      //       }
      //     ]
      //   },
      //   {
      //     "code": "M13",
      //     "name": "代码管理",
      //     "alias": "代码接口",
      //     "category": "serviceType"
      //   },
      //   {
      //     "code": "M14",
      //     "name": "标签管理",
      //     "alias": "标签接口",
      //     "category": "serviceType"
      //   },
      //   {
      //     "code": "M16",
      //     "name": "权限管理",
      //     "alias": "权限接口",
      //     "category": "serviceType"
      //   },
      //   {
      //     "code": "M17",
      //     "name": "系统配置",
      //     "alias": "系统接口",
      //     "category": "serviceType"
      //   },
      //   {
      //     "code": "M18",
      //     "name": "备份与恢复",
      //     "alias": "备份接口",
      //     "category": "serviceType"
      //   },
      //   {
      //     "code": "M19",
      //     "name": "全局消息提醒配置",
      //     "alias": "消息接口",
      //     "category": "serviceType"
      //   },
      //   {
      //     "code": "M21",
      //     "name": "资讯发布",
      //     "alias": "资讯接口",
      //     "category": "serviceType"
      //   },
      //   {
      //     "code": "M22",
      //     "name": "任务管理",
      //     "alias": "任务接口",
      //     "category": "serviceType"
      //   },
      //   {
      //     "code": "M23",
      //     "name": "应用管理",
      //     "alias": "应用接口",
      //     "category": "serviceType"
      //   }
      // ]
      if (this.type === 'edit' || this.type === 'data') {
        this.getAppDetail()
        // this.getAppStatistic()
        // this.getAppStatisticList()
      } else {
        let options = JSON.parse(JSON.stringify(this.openapi))
        options.map(item => {
          if (item.children) {
            let status = true
            item.children.map(item2 => {
              if (!item2.selected) {
                status = false
              }
            })
            item['checkedAll'] = status
          }
        })
        this.form.authorization = JSON.parse(JSON.stringify(this.openapi))
      }
    }
  },
  created () {
    switch (this.$route.query.type) {
      case 'edit': 
        this.menuIndex = 2
        break
      case 'data':
        this.menuIndex = 3
        break
      default:
        this.menuIndex = 1
        break
    }
    this.type = this.$route.query.type
    this.currentId = this.$route.query.id
    this.currentAppId = this.$route.query.appid
    this.getDepartment()
  },
  mounted () {
  }
};
</script>

<style scoped>
.batch-menu {
  width: 100%;
  padding-top: 50px;
}
.batch-menu ul li {
  line-height: 50px;
  cursor: pointer;
  font-size: 16px;
  color: rgba(0,0,0,0.65);
  text-indent: 40px;
}
.batch-menu ul li.cur {
  color: rgba(0,0,0,0.85);
  font-weight: bold;
  background: #E6F7FF;
}

.form-box {
  margin-top: 20px;
}

.form-content {
  border: 1px solid rgba(0,0,0,0.15);
  border-radius: 2px;
  padding: 10px;
  margin-bottom: 5px;
  /*width: 700px;*/
}
.form-content-box {
  padding: 5px 0;
}
.form-content-box1 {
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0,0,0,0.06);
}
.form-content-box2 {
  padding: 10px 0;
  border-bottom: none;
}
/*.form-content div:last-child .form-content-box{
  border-bottom: none;
  padding-bottom: 0px;
}*/
.no-bor {
  border: none;
  padding-bottom: 0;
}

.drawer-bar {
  padding: 5px 0 20px;
}

.box-title .fl {
  color: rgba(0,0,0,0.85);
  font-weight: bold;
}
.box-con {
  border-bottom: 1px solid rgba(0,0,0,0.06);
}
.box2 {
  margin-top: 20px;
}
.no-bor {
  border: none;
}

.add-item {
  border-bottom: 1px solid rgba(0,0,0,0.06);
  padding: 10px 0 10px 30px;
}

.jk-list {
  width: 100%;
}
.jk-list li {
  width: 100%;
}
.jk-list li span {
  color: #999;
}
.auth-box-ul {
  display: block;
  width:100%;
  height:auto;
  overflow:hidden;
}
.auth-box-ul li {
  width: 33%;
  float: left;
  margin-top: 10px;
}
.drawer-bar {
  color: rgba(0,0,0,0.85);
}
</style>
